<template>
  <span>
    <h1 class="headline blue--text text-center mt-4 mb-3">Prepaid raportti</h1>
    <v-divider></v-divider>
    Valitse päivämäärä:
      <v-row>
          <v-col cols="2">
              <v-text-field v-model="startDate" placeholder="01.12.23" label="alku"/>
          </v-col>
          <v-col cols="2">
              <v-text-field v-model="endDate" placeholder="31.12.23" label="loppu"></v-text-field>
          </v-col>
          <v-col cols="1">
              <v-btn
                    class="v-btn ma-3 green float-right"
                    @click="getResource"
                    small
              >
                  HAE
              </v-btn>
          </v-col>
      </v-row>
      <v-card>
          <v-card-text>
              <v-simple-table v-if="!resourceLoading">
                  <template v-slot:default>
                    <thead>
                      <tr>
                          <th class="text-left">
                              Ladattu prepaid
                          </th>
                          <th class="text-left">
                              Käytetty prepaid
                          </th>
                          <th class="text-left">
                              Käyttämätön prepaid
                          </th>
                          <th class="text-left">
                              Annettu bonus
                          </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td>
                              KP1: {{ resource.kp1_loaded_for_period }}<br/>
                              KP2: {{ resource.kp2_loaded_for_period }}<br/>
                              yht (Alv0%): {{ resource.total_loaded_for_period_vat0 }}<br/>
                              yht (Alv25.5%): {{ resource.total_loaded_for_period_vat255 }}
                          </td>
                          <td>
                              yht (Alv0%): {{ resource.total_used_for_period_vat0 }}<br/>
                              yht (Alv25.%): {{ resource.total_used_for_period_vat255 }}
                          </td>
                          <td>
                              KP1: {{ resource.kp1_left_for_period }}<br/>
                              KP2: {{ resource.kp2_left_for_period }}<br/>
                              yht: {{ resource.total_left_for_period }}
                          </td>
                          <td>
                              KP1: {{ resource.kp1_bonus_for_period }}<br/>
                              KP2: {{ resource.kp2_bonus_for_period }}<br/>
                              yht: {{ resource.total_bonus_for_period }}
                          </td>
                      </tr>
                    </tbody>
                  </template>
              </v-simple-table>
              <v-skeleton-loader v-else>
                  Ladataan
              </v-skeleton-loader>
          </v-card-text>
    </v-card>
  </span>
</template>

<script>
import moment from "moment";
export default {
    name: "PrepaidReporting",
    data: function () {
        return {
            startDate: null,
            endDate: null,
            copied: false,
            resourceLoading: false,
            resource: [],
        }
    },
    methods:{
        async getResource(){
            this.resourceLoading = true
            this.copied = false
            this.resource = []
            await this.axios.get('reports/prepaid_report', {
                params:{
                  from: this.startDate,
                  to: this.endDate,
                  include_test: 0,
                }
            }).then((resources) => {
                this.resource = resources.data
            }).catch ((error) => {
                console.error(error);
            });
            this.resourceLoading = false
        },
    },
    mounted(){
        const prevMonth = moment().subtract(1, 'months');
        this.startDate = prevMonth.startOf('month').format('DD.MM.YY');
        this.endDate = prevMonth.endOf('month').format('DD.MM.YY');
        ///api/reports/agents/prepaid_report?from=01.05.23&to=31.05.23&include_test=0
        this.getResource()
    }

}
</script>

<style scoped>

</style>